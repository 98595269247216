<template>
  <div class="flat-gallery">
    <content-wrapper-block>
      <div class="header">
        <page-title>Галерея</page-title>
        <div class="nav">
          <div
            class="nav-btn nav-prev"
            role="button"
          >
            <icon-arrow-left />
          </div>
          <div
            class="nav-btn nav-next"
            role="button"
          >
            <icon-arrow-right />
          </div>
        </div>
      </div>
      <div class="text">
        <p>
          Уникальное предложение: сочетание комфорта четырехкомнатных апартаментов с потрясающими
          панорамными видами, открывающимися с 26-го этажа.
        </p>
        <br />
        <p>
          Вас ждет просторная гостиная, две роскошные спальни с собственными ванными комнатами,
          функциональный кабинет и вместительная гардеробная, а также два санузла. Приятный бонус –
          дизайн-проект в подарок!
        </p>
      </div>
      <swiper
        ref="swiper"
        :options="{
          navigation: {
            nextEl: '.flat-gallery .nav-next',
            prevEl: '.flat-gallery .nav-prev',
          },
          breakpoints: {
            0: {
              spaceBetween: 15,
              slidesPerView: 'auto',
            },
            734: {
              spaceBetween: 30,
              slidesPerView: 'auto',
            },
          },
        }"
      >
        <swiper-slide
          v-for="(slide, i) in slides"
          :key="i"
        >
          <img
            :src="slide"
            alt=""
          />
        </swiper-slide>
      </swiper>
      <div class="nav nav--mobile">
        <div
          class="nav-btn nav-prev"
          role="button"
        >
          <icon-arrow-left />
        </div>
        <div
          class="nav-btn nav-next"
          role="button"
        >
          <icon-arrow-right />
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import ContentWrapperBlock from '@/components/blocks/ContentWrapperBlock.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import IconArrowRight from '@/components/icons/IconArrowRight.vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    ContentWrapperBlock,
    PageTitle,
    IconArrowLeft,
    IconArrowRight,
  },
  props: {
    slides: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
</script>

<style lang="scss" scoped>
.flat-gallery {
  overflow: hidden;

  @media (min-width: $screen-sm) {
    padding-top: 80px;
  }

  ::v-deep {
    .content-wrapper,
    .swiper-container {
      overflow: visible;
    }

    .page-title__header {
      margin-top: 0;
    }

    .nav-btn svg {
      width: 36px;

      @media (min-width: $screen-sm) {
        width: 48px;
      }

      circle,
      path {
        stroke: currentColor;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .text {
    max-width: 900px;
    color: #18181bcc;
    font-size: 14px;
    line-height: 1;
    font-family: $font-family-rubik;
    margin-bottom: 30px;

    @media (min-width: $screen-sm) {
      font-size: 16px;
      margin-bottom: 40px;
    }

    p {
      margin: 0;
    }
  }

  .swiper-slide {
    width: auto;

    img {
      max-height: 398px;
      object-fit: contain;
    }
  }

  .nav {
    display: none;
    gap: 16px;
    user-select: none;

    @media (min-width: $screen-sm) {
      display: flex;
      gap: 24px;
    }

    &--mobile {
      display: flex;
      width: fit-content;
      margin: 15px auto 0;

      @media (min-width: $screen-sm) {
        display: none;
      }
    }
  }

  .nav-btn {
    color: #e14313;
    cursor: pointer;

    &:hover {
      color: #000;
    }

    &.swiper-button-disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
</style>
