var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flat-gallery" },
    [
      _c(
        "content-wrapper-block",
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("page-title", [_vm._v("Галерея")]),
              _c("div", { staticClass: "nav" }, [
                _c(
                  "div",
                  {
                    staticClass: "nav-btn nav-prev",
                    attrs: { role: "button" },
                  },
                  [_c("icon-arrow-left")],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "nav-btn nav-next",
                    attrs: { role: "button" },
                  },
                  [_c("icon-arrow-right")],
                  1
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "text" }, [
            _c("p", [
              _vm._v(
                " Уникальное предложение: сочетание комфорта четырехкомнатных апартаментов с потрясающими панорамными видами, открывающимися с 26-го этажа. "
              ),
            ]),
            _c("br"),
            _c("p", [
              _vm._v(
                " Вас ждет просторная гостиная, две роскошные спальни с собственными ванными комнатами, функциональный кабинет и вместительная гардеробная, а также два санузла. Приятный бонус – дизайн-проект в подарок! "
              ),
            ]),
          ]),
          _c(
            "swiper",
            {
              ref: "swiper",
              attrs: {
                options: {
                  navigation: {
                    nextEl: ".flat-gallery .nav-next",
                    prevEl: ".flat-gallery .nav-prev",
                  },
                  breakpoints: {
                    0: {
                      spaceBetween: 15,
                      slidesPerView: "auto",
                    },
                    734: {
                      spaceBetween: 30,
                      slidesPerView: "auto",
                    },
                  },
                },
              },
            },
            _vm._l(_vm.slides, function (slide, i) {
              return _c("swiper-slide", { key: i }, [
                _c("img", { attrs: { src: slide, alt: "" } }),
              ])
            }),
            1
          ),
          _c("div", { staticClass: "nav nav--mobile" }, [
            _c(
              "div",
              { staticClass: "nav-btn nav-prev", attrs: { role: "button" } },
              [_c("icon-arrow-left")],
              1
            ),
            _c(
              "div",
              { staticClass: "nav-btn nav-next", attrs: { role: "button" } },
              [_c("icon-arrow-right")],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }